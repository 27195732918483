@assets-path-base: "./assets";

@font-face {
  font-family: 'Roboto Condensed';
  src: url('@{assets-path-base}/font/RobotoCondensed-Regular.ttf') format("truetype");
}

@hde-company-blue: #2C61AE;
@body-backgroud: #243238;
@body-background-alt: #2cae84;
@body-backgroud-shade: #f5f5f5;
@body-colour: #FFFFFF;
@DefaultBorderSize: 30px;

@font-light-colour: @body-colour;
@font-dark-colour: @body-backgroud;

@font-root-element: 16px;
@font-xm: 1rem;
@font-sm: 1.2rem;
@font-mid: 1.5rem;
@font-lg: 2.4rem;
@font-xl: 3.3rem;

.SetStandardBorder() {
  border: 1px solid fade(@body-backgroud, 40%);
}
.SetButtonStyle() {
  text-decoration:  none;
  float:right;
  width: auto;
  background: @hde-company-blue;
  color: @font-light-colour;
  padding: 5px 10px;
  margin-left: 5px;
  margin-top: 5px;
  &:hover {
    color: @hde-company-blue;
    background: @font-light-colour;
  }
}
.SetWidgetBorder(@colour: @body-backgroud, @top-pixel: @DefaultBorderSize, @bottom-pixel: @DefaultBorderSize) {
  border: 0;
  border-top: @top-pixel solid @colour;
  border-bottom: @bottom-pixel solid @colour;
}

.setStandardWrapperSize(@bkColour: @body-backgroud, @fontColour: @font-light-colour, @topSpace: 60px, @botSpace: 60px) {
  width:100%;
  background-color:  @bkColour ;
  color: @fontColour;
  padding: 0 5%;
  padding-top: @topSpace;
  padding-bottom: @botSpace;
  font-size: @font-sm;
}


@default-font: 'Roboto Condensed', 'sans-serif', 'Times New Roman', 'Helvetica';


// Taken from Bootstrap config
@screen-xs: 480px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1200px;
