.Table-container {
  background-color: rgba(0,0,0,0);
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 999;
  color: @font-dark-colour;

  .table-of-content-action {
    padding: 0;
    margin: 0;
    cursor: pointer;
    section {
      background-color: fade(@body-backgroud, 90%);
      color: white;
      width: 200px;
      text-align: center;
      .rotate {
        transform: rotate(180deg);
        transition: transform 0.4s ease;
      }
      i {
        transition: transform 0.4s ease;
      }

    }
  }
}

#TBC_Container {
    background-color: @body-colour;
  display: none;
  border: 0;
  border-right: 1px solid @body-backgroud;
  border-top: 1px solid @body-backgroud;
  overflow-y: scroll;
  padding: 10px;
  max-height: 50vh;
}

#TBC_ContainerTitle {
  display: none;
  visibility: hidden;
}

#TBC_Content {
  padding: 0;
  margin: 0;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      font-size: @font-xm;
      a {
        color: @font-dark-colour;

      }
    }

    .TB_Level1 {
      margin-left: 0px;
      font-size: @font-mid;
    }
    .TB_Level2 {
      margin-left: 10px;
    }
    .TB_Level3 {
      margin-left: 20px;
    }
    .TB_Level4 {
      margin-left: 20px;
    }
    .TB_Level5 {
      margin-left: 20px;
    }
    .TB_Level6 {
      margin-left: 20px;
    }
  }
}
