.service-widget {
  background: @body-colour; //@hde-company-blue
  color: @font-dark-colour;
  padding-bottom: 5px;
  .service-widget-container{

    display: flex;
    flex-wrap: wrap;
    min-height: 40vh;
    .service-left, .service-right, .service-title{
      flex: 1 1 50%;
      width: 100%;
      .service-post, .service-feature {
        display: none;
        opacity: 0;
      }
      .service-feature-show, .service-post-show {
        opacity: 1;
      }
      .service-post-show {
        display: block;
      }
      .service-feature-show {
        display: flex!important;
      }
    }
    .service-title {
      padding: 0 5%;
      flex: 1 1 100%;
      h1 {
        width: 100%;
        font-size: @font-lg;
      }
    }
    .service-left {
      padding: 0 5%;
      .service-icon {
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
         i {
           flex: 0 1 10%;
           font-size: @font-xl;
           margin: 0;
           cursor: pointer;
           text-align: center;

         }
         .service-icon-selected {
           color: @hde-company-blue;
         }
      }
      .service-post{
        float: left;
        width: 100%;
        height: 300px;
        padding-top: 20px;
        .serice-read-more {
          padding: 30px  20px;
          width: 100%;
          a {
            .SetButtonStyle();

          }
        }
        .service-title {
          width: 100%;
          font-size: @font-mid;
          color: @hde-company-blue;
          text-transform: uppercase;
        }
        .service-content {
          font-size: @font-mid;
        }
      }
      .service-post-show {
        display: block;
        opacity: 1;
      }
    }

    .service-right {
      .service-feature {
        width: 100%;
        height: 100%;
        display: none; // override by .service-feature-show
        justify-content:center;
        padding: 20px;
        .service-img {
          width: 100%;
          min-height: 100%;
          background-position: 50% 50%!important;
          background-repeat:no-repeat!important;
          background-size: cover!important;
          .SetStandardBorder();
        }
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .service-widget {
    .service-widget-container{
      .service-left, .service-right {
        flex: 1 1 100%;
      }
      .service-right {
        .service-feature {
          height: 300px;
          .service-img {

          }
        }
      }
      .service-left {
        order: 2;
        .service-icon {
           span {
             flex-basis: 25%;
           }
        }
        .service-post{
          float: left;
          width: 100%;
          height: 200px;
        }
      }
    }
  }
}
