/*!
Theme Name: HDE2017
Theme URI: https://www.hashDefineElectronics.com
Author: Ronald Sousa @Opticalworm
Version: 2.0
Author URI: https://www.hashDefineElectronics.com
Description: The 2017 theme for Hash Define Electronics Ltd website
Tags: Electronics, Hardware, Firmware
*/
@font-face {
  font-family: 'Roboto Condensed';
  src: url('assets/font/RobotoCondensed-Regular.ttf') format("truetype");
}
* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
html a {
  color: #2C61AE;
  text-decoration: none;
}
html a:hover {
  text-decoration: underline;
}
ul,
menu,
dir {
  margin: 0;
  padding: 0;
}
body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  background-color: #FFFFFF;
  color: #243238;
  font-family: 'Roboto Condensed', 'sans-serif', 'Times New Roman', 'Helvetica';
  font-size: 1.5rem;
  overflow-x: hidden;
}
@media screen and (max-width: 768px) {
  .header .header-nav {
    float: none!important;
    margin: 0 auto;
    padding-top: 10px!important;
  }
  .header .header-logo a img {
    margin: 0 auto;
  }
}
.header {
  position: absolute;
  background-color: rgba(36, 50, 56, 0.5);
  border-bottom: 1px solid rgba(36, 50, 56, 0.4);
  width: 100%;
  padding: 10px 15px;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.header .header-item {
  flex: 1;
  flex-basis: 50%;
  flex-shrink: 0;
}
.header .header-logo {
  top: 10px;
  min-width: 300px;
}
.header .header-logo a {
  width: 100%;
  display: block;
}
.header .header-logo a img {
  display: block;
  height: 60px;
}
.header .header-nav {
  display: table;
  float: right;
  color: #FFFFFF;
  font-size: 1.2rem;
}
.header .header-nav .current-menu-item > a {
  border-bottom: 5px solid #2C61AE;
}
.header .header-nav li {
  display: block;
  float: left;
  padding: 0 8px;
}
.header .header-nav .menu-item-has-children {
  display: block;
  position: relative;
}
.header .header-nav .menu-item-has-children i {
  padding-left: 5px;
}
.header .header-nav a {
  text-decoration: none;
  color: #FFFFFF;
}
.header .header-nav a:hover {
  color: rgba(255, 255, 255, 0.5);
}
.header .header-nav li:last-child:after {
  margin-right: 0px;
}
.header .header-nav .sub-menu {
  opacity: 0;
  background-color: #243238;
  position: absolute;
  left: 0;
  padding: 5px;
  border-top: 5px solid #243238;
  box-sizing: border-box;
}
.header .header-nav .sub-menu li {
  display: flex;
  float: none;
  width: 100%;
  text-align: center;
}
.header .header-nav .sub-menu li a,
.header .header-nav .sub-menu li i {
  flex: 1;
}
.header .header-nav .sub-menu li i {
  font-size: 1rem;
  padding: 10px;
}
.header .header-nav .menu-item-has-children:hover {
  background-color: #243238;
}
.header .header-nav .menu-item-has-children:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s, opacity 0.5s linear;
}
/**
* HDE Banner create by plugin/hde-extension/lib/page-banner.php
*/
@media screen and (max-width: 768px) {
  .banner .banner-video {
    height: 100vh!important;
  }
  .banner .banner-video .banner-widget-chevron {
    display: flex!important;
  }
}
.banner .banner-video {
  min-height: 300px;
  height: 40vh;
  position: relative;
  overflow: hidden;
  /**
      Banner content section
      */
}
.banner .banner-video .video-widget {
  width: 100%;
  padding: 0;
  margin: 0;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
}
.banner .banner-video .image-widget {
  min-width: 50%;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -9999;
}
.banner .banner-video .banner-widget,
.banner .banner-video .banner-widget-chevron {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.banner .banner-video .banner-widget .content,
.banner .banner-video .banner-widget-chevron .content {
  align-self: center;
  flex: 0 1 auto;
  max-width: 80vw;
  padding: 20px;
  color: #FFFFFF;
  text-align: center;
}
.banner .banner-video .banner-widget .content .banner-title,
.banner .banner-video .banner-widget-chevron .content .banner-title {
  font-size: 1.5rem;
  text-transform: uppercase;
}
.banner .banner-video .banner-widget .content .banner-body,
.banner .banner-video .banner-widget-chevron .content .banner-body {
  font-size: 1.2rem;
}
.banner .banner-video .banner-widget .content .button a,
.banner .banner-video .banner-widget-chevron .content .button a {
  background-color: #FFFFFF;
  border: none;
  color: black ;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 5px;
  margin-top: 10px;
}
.banner .banner-video .banner-widget .content .button a:hover,
.banner .banner-video .banner-widget-chevron .content .button a:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.banner .banner-video .banner-widget {
  background-color: rgba(36, 50, 56, 0.6);
}
.banner .banner-video .banner-widget-chevron {
  cursor: pointer;
  display: none;
  justify-content: flex-end;
}
.banner .banner-video .banner-widget-chevron .content {
  width: auto;
  padding: 0 10px;
  border-radius: 20px;
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .banner-widget .content .banner-title {
    font-size: 2.4rem !important;
  }
  .banner-widget .content .banner-body {
    font-size: 1.5rem !important;
  }
}
.single-wrapper {
  width: 100%;
  background-color: #FFFFFF;
  color: #243238;
  padding: 0 5%;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 1.2rem;
}
.single-wrapper .single-container {
  margin: 10px;
}
.single-wrapper .single-container .podcast_player {
  display: inline-block;
  width: 100%;
}
.single-wrapper .single-container .podcast_meta {
  display: inline-block;
  width: 100%;
}
.single-wrapper .single-container .podcast_meta a {
  text-decoration: none;
  float: right;
  width: auto;
  background: #2C61AE;
  color: #FFFFFF;
  padding: 5px 10px;
  margin-left: 5px;
  margin-top: 5px;
}
.single-wrapper .single-container .podcast_meta a:hover {
  color: #2C61AE;
  background: #FFFFFF;
}
.single-wrapper .single-container .podcast-subscribe {
  display: flex;
  flex-wrap: nowrap;
  padding: 5px;
  justify-content: space-between;
}
.single-wrapper .single-container .podcast-subscribe a {
  flex: 1 1 auto;
  float: left;
  display: block;
  min-width: 120px;
  text-align: center;
  padding: 5px;
  background: #243238;
  color: #FFFFFF;
  margin: 1px;
  font-size: 1rem;
}
.single-wrapper .single-container .podcast-subscribe a:hover {
  text-decoration: none;
  background: #FFFFFF;
  color: #243238;
}
.single-wrapper .single-container ul {
  padding-left: 30px;
}
.single-wrapper .single-container .aligncenter,
.single-wrapper .single-container iframe {
  display: block;
  margin: 0 auto;
}
.single-wrapper .single-container pre {
  display: inline-block;
  white-space: pre-wrap;
  padding: 5px;
  width: 100%;
  height: auto;
  font-size: 1rem;
  background: rgba(44, 97, 174, 0.5);
  overflow: hidden;
}
.single-wrapper .single-container .ImageGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.single-wrapper .single-container .ImageGallery p {
  flex: 0 1 auto;
}
iframe {
  border: 1px solid #243238;
  max-width: 100%;
}
.TerminalCode {
  word-break: break-all;
}
.size-full,
.size-large {
  max-width: 100%;
  height: auto;
  border: 1px solid #243238;
}
.archive-page {
  background-color: white;
  width: 100%;
  margin: 0;
  padding: 1% 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.archive-page .post-container {
  text-decoration: none;
  flex: 1 1 20%;
  position: relative;
  overflow: hidden;
  display: block;
  float: left;
  height: 25vh;
  min-height: 200px;
  margin: 0;
  margin: 2px;
  background-color: rgba(44, 97, 174, 0.8);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgba(36, 50, 56, 0.4);
}
.archive-page .post-container .post-content {
  display: flex;
  opacity: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0;
  align-items: flex-end;
}
.archive-page .post-container .post-content .post-excerpt {
  background-color: rgba(36, 50, 56, 0.8);
  flex: 1 1 auto;
  justify-content: center;
  position: absolute;
  color: #FFFFFF;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  left: -200%;
  left: 0;
  margin: 0;
  padding: 0 0px;
}
.archive-page .ajax-loading {
  display: none;
}
@media screen and (max-width: 992px) {
  .archive-page .post-container {
    flex: 1 1 40%;
    min-height: 200px;
  }
}
@media screen and (max-width: 768px) {
  .archive-page .post-container {
    flex: 1 1 100%;
    min-height: 200px;
  }
}
.comment-wrapper {
  width: 100%;
  background-color: #243238;
  color: #FFFFFF;
  padding: 0 5%;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 1.2rem;
}
.comment-wrapper .comments-title {
  width: 100%;
  font-size: 2.4rem;
}
.comment-wrapper .comments-title small {
  font-size: 1.2rem;
}
.comment-wrapper .comment-posts {
  width: 100%;
  margin: 10px;
}
.comment-wrapper .comment-posts .depth-1 {
  padding-top: 20px;
}
.comment-wrapper .comment-posts .comment {
  background: #FFFFFF;
  color: #243238;
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
  padding: 5px;
  align-items: center;
  justify-content: center;
}
.comment-wrapper .comment-posts .comment .comment-author {
  flex: 0 1 auto;
  font-size: 2.4rem;
  align-self: center;
}
.comment-wrapper .comment-posts .comment .comment-body {
  flex: 1 1 75%;
  width: 75%;
  box-sizing: border-box;
  align-self: center;
}
.comment-wrapper .comment-posts .comment .comment-body p {
  margin: 0;
  margin-bottom: 10px;
  width: 100%;
  word-wrap: break-word;
}
.comment-wrapper .comment-posts .children {
  padding-left: 20%;
}
.comment-wrapper .comment-posts .comment-author small {
  display: block;
}
.comment-wrapper .comment-posts .comment-author img {
  padding: 2px;
  max-width: 100px;
  height: auto;
  margin: 0 auto;
}
.comment-wrapper .comment-posts .comment-body {
  margin: 5px;
}
.comment-wrapper .comment-posts .comment-post-footer {
  width: 100%;
  background: #FFFFFF;
  color: #243238;
  padding: 5px;
  margin-bottom: 10px;
  text-align: right;
}
.comment-wrapper .comment-posts .comment-post-footer .comment-edit-link,
.comment-wrapper .comment-posts .comment-post-footer .comment-reply-link {
  background: #243238;
  color: #FFFFFF;
  padding: 5px;
}
.reply-wrapper {
  width: 100%;
  background-color: #243238;
  color: #FFFFFF;
  padding: 0 5%;
  padding-top: 0px;
  padding-bottom: 120px;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
}
.reply-wrapper .reply-section-left,
.reply-wrapper .comment-respond {
  flex: 1 1 50%;
  font-size: 1.5rem;
}
.reply-wrapper .reply-section-left h1,
.reply-wrapper .comment-respond h1 {
  font-size: 2.4rem;
}
.reply-wrapper .comment-respond label[for=comment],
.reply-wrapper .comment-respond label[for=author],
.reply-wrapper .comment-respond label[for=si_captcha_code],
.reply-wrapper .comment-respond #si_captcha_code_p span,
.reply-wrapper .comment-respond label[for=email],
.reply-wrapper .comment-respond label[for=url],
.reply-wrapper .comment-respond .comment-notes,
.reply-wrapper .comment-respond .comment-reply-title {
  display: none;
}
.reply-wrapper .comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
}
.reply-wrapper .comment-respond .comment-form p,
.reply-wrapper .comment-respond .comment-form .si_captcha_small {
  flex: 0 1 50%;
  font-size: 1.5rem;
  padding: 5px;
  margin: 0;
}
.reply-wrapper .comment-respond .comment-form p input[type=submit],
.reply-wrapper .comment-respond .comment-form .si_captcha_small input[type=submit] {
  width: auto;
  color: #243238;
  background: #FFFFFF;
  border: 0;
  float: right;
}
.reply-wrapper .comment-respond .comment-form p i,
.reply-wrapper .comment-respond .comment-form .si_captcha_small i {
  width: 100%;
}
.reply-wrapper .comment-respond .comment-form p textarea,
.reply-wrapper .comment-respond .comment-form .si_captcha_small textarea {
  height: 80px;
  width: 100%;
  max-width: 100%;
  resize: vertical;
}
.reply-wrapper .comment-respond .comment-form p input,
.reply-wrapper .comment-respond .comment-form .si_captcha_small input {
  width: 100%;
  font-size: 1.2rem;
}
.reply-wrapper .comment-respond .comment-form p a,
.reply-wrapper .comment-respond .comment-form .si_captcha_small a {
  color: #FFFFFF;
}
.reply-wrapper .comment-respond .comment-form-comment,
.reply-wrapper .comment-respond .comment-form-url,
.reply-wrapper .comment-respond .form-submit {
  flex-basis: 100% !important;
}
.to-the-top {
  display: none;
  opacity: 0;
  transition: opacity 1 linear;
}
.to-the-top i {
  position: fixed;
  bottom: 5px;
  right: 5px;
  background-color: rgba(36, 50, 56, 0.5);
  color: white;
  padding: 2px 4px;
  border-radius: 50px;
  margin: 0;
  font-size: 2.4rem;
  cursor: pointer;
}
.to-the-top-show {
  display: block;
  opacity: 1;
  transition: opacity 0.4s ease;
}
.Table-container {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 999;
  color: #243238;
}
.Table-container .table-of-content-action {
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.Table-container .table-of-content-action section {
  background-color: rgba(36, 50, 56, 0.9);
  color: white;
  width: 200px;
  text-align: center;
}
.Table-container .table-of-content-action section .rotate {
  transform: rotate(180deg);
  transition: transform 0.4s ease;
}
.Table-container .table-of-content-action section i {
  transition: transform 0.4s ease;
}
#TBC_Container {
  background-color: #FFFFFF;
  display: none;
  border: 0;
  border-right: 1px solid #243238;
  border-top: 1px solid #243238;
  overflow-y: scroll;
  padding: 10px;
  max-height: 50vh;
}
#TBC_ContainerTitle {
  display: none;
  visibility: hidden;
}
#TBC_Content {
  padding: 0;
  margin: 0;
}
#TBC_Content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#TBC_Content ul li {
  font-size: 1rem;
}
#TBC_Content ul li a {
  color: #243238;
}
#TBC_Content ul .TB_Level1 {
  margin-left: 0px;
  font-size: 1.5rem;
}
#TBC_Content ul .TB_Level2 {
  margin-left: 10px;
}
#TBC_Content ul .TB_Level3 {
  margin-left: 20px;
}
#TBC_Content ul .TB_Level4 {
  margin-left: 20px;
}
#TBC_Content ul .TB_Level5 {
  margin-left: 20px;
}
#TBC_Content ul .TB_Level6 {
  margin-left: 20px;
}
.service-widget {
  background: #FFFFFF;
  color: #243238;
  padding-bottom: 5px;
}
.service-widget .service-widget-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 40vh;
}
.service-widget .service-widget-container .service-left,
.service-widget .service-widget-container .service-right,
.service-widget .service-widget-container .service-title {
  flex: 1 1 50%;
  width: 100%;
}
.service-widget .service-widget-container .service-left .service-post,
.service-widget .service-widget-container .service-right .service-post,
.service-widget .service-widget-container .service-title .service-post,
.service-widget .service-widget-container .service-left .service-feature,
.service-widget .service-widget-container .service-right .service-feature,
.service-widget .service-widget-container .service-title .service-feature {
  display: none;
  opacity: 0;
}
.service-widget .service-widget-container .service-left .service-feature-show,
.service-widget .service-widget-container .service-right .service-feature-show,
.service-widget .service-widget-container .service-title .service-feature-show,
.service-widget .service-widget-container .service-left .service-post-show,
.service-widget .service-widget-container .service-right .service-post-show,
.service-widget .service-widget-container .service-title .service-post-show {
  opacity: 1;
}
.service-widget .service-widget-container .service-left .service-post-show,
.service-widget .service-widget-container .service-right .service-post-show,
.service-widget .service-widget-container .service-title .service-post-show {
  display: block;
}
.service-widget .service-widget-container .service-left .service-feature-show,
.service-widget .service-widget-container .service-right .service-feature-show,
.service-widget .service-widget-container .service-title .service-feature-show {
  display: flex!important;
}
.service-widget .service-widget-container .service-title {
  padding: 0 5%;
  flex: 1 1 100%;
}
.service-widget .service-widget-container .service-title h1 {
  width: 100%;
  font-size: 2.4rem;
}
.service-widget .service-widget-container .service-left {
  padding: 0 5%;
}
.service-widget .service-widget-container .service-left .service-icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.service-widget .service-widget-container .service-left .service-icon i {
  flex: 0 1 10%;
  font-size: 3.3rem;
  margin: 0;
  cursor: pointer;
  text-align: center;
}
.service-widget .service-widget-container .service-left .service-icon .service-icon-selected {
  color: #2C61AE;
}
.service-widget .service-widget-container .service-left .service-post {
  float: left;
  width: 100%;
  height: 300px;
  padding-top: 20px;
}
.service-widget .service-widget-container .service-left .service-post .serice-read-more {
  padding: 30px  20px;
  width: 100%;
}
.service-widget .service-widget-container .service-left .service-post .serice-read-more a {
  text-decoration: none;
  float: right;
  width: auto;
  background: #2C61AE;
  color: #FFFFFF;
  padding: 5px 10px;
  margin-left: 5px;
  margin-top: 5px;
}
.service-widget .service-widget-container .service-left .service-post .serice-read-more a:hover {
  color: #2C61AE;
  background: #FFFFFF;
}
.service-widget .service-widget-container .service-left .service-post .service-title {
  width: 100%;
  font-size: 1.5rem;
  color: #2C61AE;
  text-transform: uppercase;
}
.service-widget .service-widget-container .service-left .service-post .service-content {
  font-size: 1.5rem;
}
.service-widget .service-widget-container .service-left .service-post-show {
  display: block;
  opacity: 1;
}
.service-widget .service-widget-container .service-right .service-feature {
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  padding: 20px;
}
.service-widget .service-widget-container .service-right .service-feature .service-img {
  width: 100%;
  min-height: 100%;
  background-position: 50% 50%!important;
  background-repeat: no-repeat !important;
  background-size: cover!important;
  border: 1px solid rgba(36, 50, 56, 0.4);
}
@media screen and (max-width: 768px) {
  .service-widget .service-widget-container .service-left,
  .service-widget .service-widget-container .service-right {
    flex: 1 1 100%;
  }
  .service-widget .service-widget-container .service-right .service-feature {
    height: 300px;
  }
  .service-widget .service-widget-container .service-left {
    order: 2;
  }
  .service-widget .service-widget-container .service-left .service-icon span {
    flex-basis: 25%;
  }
  .service-widget .service-widget-container .service-left .service-post {
    float: left;
    width: 100%;
    height: 200px;
  }
}
.contact-form {
  background: #2C61AE;
  color: #FFFFFF;
  padding: 120px 0;
}
.contact-form .contact-form-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 40vh;
}
.contact-form .contact-form-container .contact-form-left,
.contact-form .contact-form-container .contact-form-right {
  flex: 0 1 50%;
  padding: 0 5%;
}
.contact-form .contact-form-container .contact-form-left h1 {
  width: 100%;
  font-size: 2.4rem;
}
.contact-form .contact-form-container .fscf-div-form form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.contact-form .contact-form-container .fscf-div-form form .fscf-div-clear {
  display: none;
}
.contact-form .contact-form-container .fscf-div-form form #fscf_div_clear1_0,
.contact-form .contact-form-container .fscf-div-form form #fscf_div_clear1_1,
.contact-form .contact-form-container .fscf-div-form form #fscf_div_clear1_3,
.contact-form .contact-form-container .fscf-div-form form .fscf-div-submit,
.contact-form .contact-form-container .fscf-div-form form #fscf_captcha_image_div1,
.contact-form .contact-form-container .fscf-div-form form .fscf_captcha_code1_class {
  display: block;
  flex: 0 1 50%;
  padding: 5px;
}
.contact-form .contact-form-container .fscf-div-form form #fscf_div_clear1_3,
.contact-form .contact-form-container .fscf-div-form form #fscf_submit_div1 {
  flex: 1 1 100%;
}
.contact-form .contact-form-container .fscf-div-form form #fscf_submit1 {
  display: inline-block;
  width: auto;
  float: right;
  background: #FFFFFF;
  color: #2C61AE;
  border: 0;
}
.contact-form .contact-form-container .contact-form-right input,
.contact-form .contact-form-container .contact-form-right textarea {
  color: #243238;
  font-size: 1.2rem;
}
.contact-form .contact-form-container .contact-form-right input {
  width: 100%;
}
.contact-form .contact-form-container .contact-form-right textarea {
  height: 80px;
  width: 100%;
  max-width: 100%;
  resize: vertical;
}
#fscf_captcha_image_div1 {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
#fscf_captcha_image_div1 #fscf_captcha_image1,
#fscf_captcha_image_div1 #fscf_captcha_refresh1 {
  flex: 1 1 10%;
}
.fscf-div-label {
  display: none;
}
@media screen and (max-width: 768px) {
  .contact-form .contact-form-container .contact-form-left,
  .contact-form .contact-form-container .contact-form-right {
    flex: 0 1 100%;
  }
}
.fscf-div-error {
  color: #FFFFFF;
  font-size: 1rem;
  text-transform: lowercase;
}
.fscf-div-error::before {
  content: '*';
}
#fscf_form_error1 {
  display: none;
}
.footer {
  background: #243238;
  color: #FFFFFF;
  width: 100%;
  padding: 120px 5%;
}
.footer .footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.footer .footer-container .footer-widget {
  flex: 0 1 50%;
}
.footer .footer-container .footer-widget h1 {
  font-size: 2.4rem;
}
.footer .footer-container .footer-widget .footer-social {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.footer .footer-container .footer-widget .footer-social li {
  flex: 0 1 auto;
  padding: 5px;
}
.footer .footer-container .footer-widget .footer-social li i {
  font-size: 2.4rem;
}
.footer .footer-container .footer-widget .footer-social li a {
  color: #FFFFFF;
  font-size: 1.2rem;
  text-decoration: none;
}
.footer .footer-container .footer-widget .footer-social li:hover {
  color: #2C61AE;
}
.footer .footer-container .footer-widget .footer-social li:hover a {
  color: #2C61AE;
}
@media screen and (max-width: 768px) {
  .footer .footer-container .footer-widget {
    flex: 0 1 100%;
  }
}
.load-more-widget {
  width: 100%;
  background-color: #FFFFFF;
  color: #FFFFFF;
  padding: 0 5%;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.load-more-widget .load-more-widget-button,
.load-more-widget .load-more-status {
  flex: 0 1 auto;
  padding: 0px 30px;
  margin: 5px;
  background: #243238;
  color: #FFFFFF;
}
.load-more-widget .load-more-widget-button {
  font-size: 2.4rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}
.load-more-widget .load-more-widget-button:hover {
  background: #2C61AE;
  transition: color 0.5s, background 0.2s;
}
.load-more-widget .load-more-status {
  flex: 1 1 100%;
  text-align: center;
  font-size: 1rem;
  color: #243238;
  background: #FFFFFF;
}
.load-more-widget .load-more-status section,
.load-more-widget .load-more-status div {
  display: inline;
}
.load-more-widget .load-more-status section label {
  font-weight: bold;
  text-transform: lowercase;
}
.load-more-widget .load-more-status div {
  cursor: pointer;
  color: #243238;
  background: #FFFFFF;
  font-weight: bold;
  padding: 5px;
}
.load-more-widget .load-more-status div:hover {
  border-bottom: 2px solid #243238;
  transition: color 0.5s, background 0.2s;
}
.about-user-container {
  width: 100%;
  background-color: #243238;
  color: #FFFFFF;
  padding: 0 5%;
  padding-top: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
}
.about-user-container section {
  flex: 1 1 75%;
  padding: 0;
}
.about-user-container section[name=image] {
  flex: 1 1 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.about-user-container section[name=image] .about-user-rotate {
  transform: rotate(180deg);
}
.about-user-container section[name=image] div {
  align-self: center;
}
.about-user-container section[name=image] div[name=images] {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center center;
  border-radius: 100%;
}
.about-user-container section[name=image] div[name=links] {
  display: flex;
  flex-wrap: wrap;
}
.about-user-container section[name=image] div[name=links] p {
  padding: 5px;
}
.about-user-container section[name=image] div[name=links] p i {
  font-size: 1.5rem;
}
.about-user-container section[name=image] div[name=links] p a {
  text-decoration: none;
  color: #FFFFFF;
}
.about-user-container section[name=image] div[name=links] p:hover i,
.about-user-container section[name=image] div[name=links] p:hover a {
  color: #2C61AE;
}
.table-responsive {
  max-width: 100%;
}
.table-responsive table,
.table-responsive th,
.table-responsive td,
.table-responsive caption {
  word-break: break-all;
  border: 1px solid #243238;
  padding: 0;
  margin: 0;
  text-align: center;
}
.most-recent-post-widget {
  width: 100%;
  background-color: #FFFFFF;
  color: #243238;
  padding: 0 5%;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.most-recent-post-widget section {
  flex: 1 1 50%;
}
.most-recent-post-widget section h1 {
  font-size: 2.4rem;
}
.most-recent-post-widget section p[name=title] {
  font-size: 1.5rem;
  color: #2C61AE;
  text-transform: uppercase;
}
.most-recent-post-widget section p {
  font-size: 1.5rem;
}
.most-recent-post-widget section p a {
  text-decoration: none;
  float: right;
  width: auto;
  background: #2C61AE;
  color: #FFFFFF;
  padding: 5px 10px;
  margin-left: 5px;
  margin-top: 5px;
}
.most-recent-post-widget section p a:hover {
  color: #2C61AE;
  background: #FFFFFF;
}
.most-recent-post-widget section[name=content] {
  padding: 0 5%;
}
.most-recent-post-widget section[name=title] {
  min-height: 0;
  padding: 0 5%;
  flex: 1 1 100%;
}
.most-recent-post-widget section[name=image] {
  padding: 20px;
}
.most-recent-post-widget section[name=image] div {
  min-width: 50%;
  height: 300px;
  background-color: #f5f5f5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  border: 1px solid rgba(36, 50, 56, 0.4);
}
@media screen and (max-width: 768px) {
  .most-recent-post-widget section {
    flex: 0 1 100%;
    padding: 0;
  }
  .most-recent-post-widget section[name=content] {
    order: 2;
  }
}
