.load-more-widget{
  .setStandardWrapperSize(@body-colour);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .load-more-widget-button, .load-more-status {
    flex: 0 1 auto;
    padding: 0px 30px;
    margin: 5px;
    background: @body-backgroud;
    color: @font-light-colour;


  }
  .load-more-widget-button {
    font-size:@font-lg;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    &:hover {
      background: @hde-company-blue;
      transition: color 0.5s, background 0.2s;
    }
  }
  .load-more-status {
    flex: 1 1 100%;
    text-align: center;
    font-size: @font-xm;
    color: @body-backgroud;
    background: @font-light-colour;
    section, div {
      display: inline;
    }
    section label {
      font-weight: bold;
      text-transform: lowercase;
    }
    div {
      cursor: pointer;
      color: @body-backgroud;
      background: @font-light-colour;
      font-weight: bold;
      padding: 5px;
      &:hover {
        border-bottom: 2px solid @body-backgroud;
        transition: color 0.5s, background 0.2s;
      }
    }
  }
}
