.comment-wrapper{
  .setStandardWrapperSize(@body-backgroud, @font-light-colour);
  .comments-title{
    width: 100%;
    font-size: @font-lg;
    small {
      font-size: @font-sm;
    }
  }
  .comment-posts {
    width: 100%;
    .depth-1 {
      padding-top: 20px;
    }
    .comment{
      background: @body-colour;
      color: @font-dark-colour;
      display: flex;
      flex-wrap: wrap;
      margin: 5px 0;
      padding: 5px;
      align-items:center;
      justify-content:center;
      .comment-author{
        flex: 0 1 auto;
        font-size: @font-lg;
        align-self:center;

      }
      .comment-body{
        flex: 1 1 75%;
        width: 75%;
        box-sizing: border-box;
        align-self: center;
        p {
          margin: 0;
          margin-bottom: 10px;
          width: 100%;
          word-wrap: break-word;
        }

      }
    }
    .children {
      padding-left: 20%;
    }
    margin: 10px;
    .comment-author {
      small {
        display: block;
      }
      img {
        padding: 2px;
        max-width: 100px;
        height: auto;
        margin: 0 auto;
      }
    }
    .comment-body {
      margin: 5px;
    }
    .comment-post-footer {
      width: 100%;
      background: @body-colour;
      color: @font-dark-colour;
      padding: 5px;
      margin-bottom: 10px;
      text-align: right;
      .comment-edit-link,
      .comment-reply-link {
        background: @font-dark-colour;
        color: @body-colour;
        padding: 5px;
      }
    }
  }
}


.reply-wrapper {
  .setStandardWrapperSize(@body-backgroud, @font-light-colour, 0px, 120px);
  display: flex;
  flex-wrap: wrap;

  .reply-section-left, .comment-respond {
      flex: 1 1 50%;
      //padding: 0 5%;
      font-size: @font-mid;
      h1 {
        font-size: @font-lg;
      }
    }
    .comment-respond {

      label[for=comment], label[for=author], label[for=si_captcha_code], #si_captcha_code_p span,
      label[for=email], label[for=url], .comment-notes, .comment-reply-title {
        display: none;
      }
        .comment-form {
          display: flex;
          flex-wrap: wrap;
          p, .si_captcha_small{
            flex: 0 1 50%;
            font-size: @font-mid;
            padding: 5px;
            margin: 0;
            input[type=submit] {
              width: auto;
              color: @font-dark-colour;
              background: @body-colour;
              border: 0;
              float: right;
            }
            i {
              width: 100%;
            }
            textarea {
              height: 80px;
              width: 100%;
              max-width: 100%;
              resize:vertical;
            }
            input {
              width: 100%;
              font-size: @font-sm;
            }
            a {
              color: @font-light-colour;
            }
          }
      }
      .comment-form-comment, .comment-form-url, .form-submit{
        flex-basis: 100% !important;
      }
  }
}
