.archive-page {
background-color: white;
  width: 100%;
  margin: 0;
  padding: 1% 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  //.SetWidgetBorder(@body-backgroud, 2px, 1px);
  .post-container {
      text-decoration: none;
      flex: 1 1 20%;
      position: relative;
      overflow: hidden;
      display: block;
      float: left;
      height: 25vh;
      //width: 400px;
      min-height: 200px;
      //min-width: 200px;
      margin: 0;
      margin: 2px;
      background-color: fade(@hde-company-blue, 80%);

      background-position: 50% 50%;
      background-repeat:   no-repeat;
      background-size:     cover;
      .SetStandardBorder();
      .post-content{
        display: flex;
        opacity: 0;
        opacity: 1;
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0;
        align-items:flex-end;


        .post-excerpt {
          background-color: fade(@body-backgroud, 80%);
          flex: 1 1 auto;
          justify-content: center;
          position: absolute;
          color:@font-light-colour;
          font-size: @font-mid;
          width: 100%;
          text-align: center;
          left:-200%;
          left: 0;
          margin: 0;
          padding: 0 0px;
        }
      }


    }
    .ajax-loading{
      display: none;
    }

}

@media screen and (max-width: @screen-md) {
  .archive-page {
    .post-container {
      flex: 1 1 40%;
      min-height: 200px;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .archive-page {
    .post-container {
      flex: 1 1 100%;
      min-height: 200px;
    }
  }
}
