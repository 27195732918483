.footer{
  background: @body-backgroud;
  color: @body-colour;
  width: 100%;
  padding: 120px 5%;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    .footer-widget{
      flex: 0 1 50%;
      h1 {
        font-size: @font-lg;
      }
      .footer-social {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content:space-around;
        li {
          flex: 0 1 auto;
          padding: 5px;
          i {
            font-size: @font-lg;
          }
          a {
            color: @body-colour;
            font-size: @font-sm;
            text-decoration: none;
          }
          &:hover {
              color: @hde-company-blue;
              a {
                color: @hde-company-blue;
              }
          }
        }
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .footer{
    .footer-container {
      .footer-widget{
        flex: 0 1 100%;
      }
    }
  }
}
