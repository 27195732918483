.most-recent-post-widget{
  .setStandardWrapperSize(@body-colour, @font-dark-colour);
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  section {
    //min-height: 40vh;
    flex: 1 1 50%;
    h1 {
      font-size: @font-lg;
    }
    p[name=title] {
      font-size: @font-mid;
      color: @hde-company-blue;
      text-transform: uppercase;
    }
    p {
      font-size: @font-mid;
      a {
        .SetButtonStyle();
      }
    }
  }
  section[name=content] {
    padding: 0 5%;
  }
  section[name=title] {
    min-height: 0;
    padding: 0 5%;
    flex: 1 1 100%;
  }
  section[name=image]{
    padding: 20px;
     div {
      min-width: 50%;
      //min-height: 100%;
      height: 300px;
      background-color: @body-backgroud-shade;
      background-position: center center;
      background-repeat: no-repeat;
      background-size:cover;
      background-origin: padding-box;
      background-clip: border-box;
      background-attachment: scroll;
      .SetStandardBorder()
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .most-recent-post-widget{
    section {
      //min-height: 300px;
      flex: 0 1 100%;
      padding: 0;
    }
    section[name=content]{
      order: 2;
    }
  }
}
