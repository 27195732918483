.table-responsive {
  max-width: 100%;
  table, th, td, caption {
    word-break: break-all;
   border: 1px solid @body-backgroud;
   padding: 0;
   margin: 0;
   text-align: center;
 }
}
