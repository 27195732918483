/**
* HDE Banner create by plugin/hde-extension/lib/page-banner.php
*/

@media screen and (max-width: @screen-sm) {
  .banner {
    .banner-video {
      height: 100vh!important;
      .banner-widget-chevron {
      display: flex!important;
      }
    }
  }
}

.banner {
  .banner-video {
      //top:-40vh;
      min-height: 300px;
      height: 40vh;
      position: relative;
      overflow: hidden;

      .video-widget{
        position: absolute;
        width: 100%;
        padding: 0;
        margin: 0;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -0;
      }

      .image-widget {
        min-width: 50%;
        min-height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -9999;
      }
      /**
      Banner content section
      */
      .banner-widget, .banner-widget-chevron{
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content:center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //background-color: fade(@body-backgroud, 60%);
          .content {
            align-self: center;
            flex: 0 1 auto;
            max-width: 80vw;
            padding: 20px;
            color: @font-light-colour;
            text-align: center;
            .banner-title {
              font-size: @font-mid;
              text-transform: uppercase;
            }
            .banner-body {
              font-size: @font-sm;
            }

            .button a {
               background-color: @font-light-colour;
               border: none;
               color: black ;
               padding: 5px;
               text-align: center;
               text-decoration: none;
               display: block;
               font-size: @font-sm;
               text-transform: uppercase;
               border-radius: 5px;
               margin-top: 10px;
            }
            .button a:hover {
              background-color: fade(@body-colour, 50%);
            }
          }
      }
      .banner-widget {
        background-color: fade(@body-backgroud, 60%);
      }
      .banner-widget-chevron {
        cursor: pointer;
        display: none;
        justify-content:flex-end;
        .content {
          width: auto;
          padding: 0 10px;
          border-radius: 20px;
          margin-bottom: 10px;
        }
      }
    }
}

@media (min-width : @screen-md) {

  .banner-widget {
      .content {
        .banner-title {
          font-size:@font-lg!important;
        }
        .banner-body {
          font-size:@font-mid !important;
        }
      }
  }
}
