.to-the-top {
  display: none;
  opacity: 0;
  transition: opacity 1 linear;
  i {
    position: fixed;
    bottom: 5px;
    right: 5px;
    background-color: fade(@body-backgroud, 50%);
    color: white;
    padding: 2px 4px;
    border-radius: 50px;
    margin: 0;
    font-size: @font-lg;
    cursor: pointer;
  }
}

.to-the-top-show {
  display: block;
  opacity: 1;
  transition: opacity 0.4s ease;
}
