@media screen and (max-width: @screen-sm) {
  .header {
    .header-nav {
      float: none!important;
      margin: 0 auto;
      padding-top: 10px!important;
    }
    .header-logo {
      a {
        img {
          margin: 0 auto;
        }
      }
    }
  }
}

.header {
  position: absolute;
  background-color: fade(@body-backgroud, 50%);
  border-bottom: 1px solid fade(@body-backgroud, 40%);
  width: 100%;
  padding: 10px 15px;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  align-items:center;
  .header-item{
    flex: 1;
    flex-basis: 50%;
    flex-shrink: 0;
  }

  .header-logo {
    top: 10px;
    min-width: 300px;
    a {
      width: 100%;
      display: block;
      img {
        display: block;
        height: 60px;
      }
    }
  }
  .header-nav {
      display: table;
      float: right;
      color: @body-colour;
      font-size: @font-sm;

      .current-menu-item >a {
        border-bottom: 5px solid #2C61AE;
      }
      li{
        display: block;
        float: left;
        padding: 0 8px;
      }
      .menu-item-has-children {
        display: block;
        position:relative;
        &:hover {
          //background-color: red;// fade(@body-backgroud, 60%);
        }
        i {
          padding-left: 5px;
        }
      }
      a {
        text-decoration: none;
        color: @body-colour;
        &:hover {
          color: fade(@body-colour, 50%);
        }
      }

      li:last-child:after{
          margin-right: 0px;
      }

      .sub-menu {
        opacity : 0;
        background-color: @body-backgroud;
      	position:absolute;
      	left:0;
      	padding: 5px;
        border-top: 5px solid @body-backgroud;
        box-sizing:border-box;

        li  {
          display: flex;
        	float:none;
        	width:100%;
          text-align: center;
          a, i {
            flex: 1;
          }
          i {
            font-size: @font-xm;
            padding: 10px;
          }
        }
      }
      .menu-item-has-children:hover {
        background-color: @body-backgroud;
        &> .sub-menu {
          opacity : 1;
          visibility:visible;
          transition: visibility 0s, opacity 0.5s linear;
        }
      }
  }
}
