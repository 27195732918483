.contact-form{
  background: @hde-company-blue;
  color: @font-light-colour;
  padding: 120px 0;
  .contact-form-container{
    display: flex;
    flex-wrap: wrap;
    min-height: 40vh;
    .contact-form-left, .contact-form-right {
      flex: 0 1 50%;
      padding: 0 5%;
    }
    .contact-form-left {
      h1 {
        width: 100%;
        font-size: @font-lg;
      }
    }

    .fscf-div-form {
      form{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .fscf-div-clear {
          display: none;
        }

        #fscf_div_clear1_0, #fscf_div_clear1_1, #fscf_div_clear1_3,
        .fscf-div-submit, #fscf_captcha_image_div1, .fscf_captcha_code1_class{
          display: block;
          flex: 0 1 50%;
          padding: 5px;
        }
        #fscf_div_clear1_3, #fscf_submit_div1 {
          flex: 1 1 100%;
        }
        #fscf_submit1 {
          display:inline-block;
          width: auto;
          float: right;
          background: @body-colour;
          color: @hde-company-blue;
          border: 0;

        }
      }
    }
    .contact-form-right {
      input, textarea {
        color: @font-dark-colour;
        font-size: @font-sm;
      }
      input {
        width: 100%;
      }
      textarea {
        height: 80px;
        width: 100%;
        max-width: 100%;
        resize:vertical;
      }
    }
  }
}

#fscf_captcha_image_div1 {
  display: flex !important;
  flex-wrap: wrap;
  align-items:center;
  justify-content:center;
  #fscf_captcha_image1, #fscf_captcha_refresh1 {
    flex: 1 1 10%;

  }
}


.fscf-div-label{
  display:  none;
}

@media screen and (max-width: @screen-sm) {
  .contact-form{
    .contact-form-container{
      .contact-form-left, .contact-form-right {
        flex: 0 1 100%;
      }
    }
  }
}

.fscf-div-error{
  color: @font-light-colour;
  font-size: @font-xm;
  text-transform: lowercase;
  &::before {
    content: '*';
  }
}
#fscf_form_error1{
  display: none;
}
