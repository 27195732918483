.about-user-container{
  .setStandardWrapperSize(@body-backgroud,@font-light-colour, 20px, 0px);
  display: flex;
  flex-wrap: wrap;
  font-size: @font-sm;
  section {
    flex: 1 1 75%;
    padding: 0;
  }
  section[name=image] {
    //margin: 20px;
    flex: 1 1 25%;
    display: flex;
    justify-content:center;
    flex-direction: column;
    .about-user-rotate {
      transform: rotate(180deg);
    }
    div {
      align-self: center;
    }
    div[name=images] {
      width: 200px;
      height: 200px;
      background-size: cover;
      background-position: center center;
      border-radius: 100%;
    }
    div[name=links] {
      display: flex;
      flex-wrap: wrap;
      p {
        padding: 5px;
        i {
          font-size: @font-mid;
        }
        a {
          text-decoration: none;
          color: @font-light-colour;
        }
        &:hover {
          i, a {
          color: @hde-company-blue;
          }
        }
      }

    }
  }
}
