* {
    box-sizing: border-box;
}
html {
  font-size: @font-root-element;
  a {
    color: @hde-company-blue;
    text-decoration: none;
    &:hover{
      text-decoration:underline;
    }
  }
}
@media screen and (max-width: @screen-sm) {
  html {
        //word-break:break-all;
  }
}
ul, menu, dir {
  margin: 0;
  padding: 0;
}
body, html {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  background-color: @body-colour;
  color:@font-dark-colour;
  font-family: @default-font;
  font-size: @font-mid;
  overflow-x: hidden;
}
