.single-wrapper {
  .setStandardWrapperSize(@body-colour, @font-dark-colour);
  .single-container {
    .podcast_player{
      display: inline-block;
      width: 100%;
    }
    .podcast_meta{
      display: inline-block;
      width: 100%;
      a {
        .SetButtonStyle();
      }
    }
    .podcast-subscribe {
      display: flex;
      flex-wrap: nowrap;
      padding: 5px;
      justify-content: space-between;
      a {
        flex: 1 1 auto;
        float: left;
        display: block;
        min-width: 120px;
        text-align: center;
        padding: 5px;
        background: @body-backgroud;
        color: @font-light-colour;
        margin: 1px;
        font-size: @font-xm;
        &:hover {
          text-decoration: none;
          background: @body-colour;
          color: @font-dark-colour;
        }
      }
    }
      margin: 10px;
      h1, h2, h3, h4, h5, h6 {
        //color: @hde-company-blue;
      }
      ul{
        padding-left: 30px;
      }


      .aligncenter , iframe{
        display: block;
        margin: 0 auto;
      }

      pre {
        display: inline-block;
        white-space:pre-wrap;
        padding: 5px;
        width: 100%;
        height: auto;
        font-size: @font-xm;
        background: fade(@hde-company-blue, 50%);
        overflow: hidden;
      }
    .ImageGallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      p {
        flex: 0 1 auto;
      }
    }
  }
}

 iframe{
  border: 1px solid @body-backgroud;
  max-width: 100%;
}

.TerminalCode {
  word-break: break-all;
}
.size-full, .size-large{
  max-width: 100%;
  height: auto;
  border: 1px solid @body-backgroud;
}
